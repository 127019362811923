import React from "react";
import { Footer } from "./footer";

export default function Research() {
  return (
    <main>
      <div className="px-4 py-6 md:px-6 md:py-12 lg:py-16 bg-[#E8F5F4]">
        <div className="prose prose-gray mx-auto max-w-6xl dark:prose-invert">
          <h1 className="text-8xl font-bold tracking-tight mb-8 text-center">
            Research
          </h1>
          <div className="space-y-8">
            <div>
              <h2 className="text-3xl font-bold tracking-tight">
                Clinical Psychology
              </h2>
              <p className="text-muted-foreground">
                Our research in clinical psychology focuses on understanding the
                psychological factors that contribute to mental health and
                well-being.
              </p>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Michelsen C, Kjellgren A. Effectiveness of Online
                    Psychotherapy to Treat and Prevent Burnout: Controlled
                    Trial. JMIR Form Res. 2022 Jun 27. PMID:{" "}
                    <a
                      href="https://doi.org/10.2196/39129"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://doi.org/10.2196/39129
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.2196/39129"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Michelsen, C. (2021) Measuring Employee Risk for Burnout.
                    Psychology, 12, 624-642. DOI:{" "}
                    <a
                      href="https://doi.org/10.4236/psych.2021.124039"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      10.4236/psych.2021.124039
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.4236/psych.2021.124039"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Michelsen, C. (2021) Empowerment for Participation:
                    Measuring Motivation, Stress, Defense Routines and
                    Engagement. Psychology, 12, 511-535. DOI:{" "}
                    <a
                      href="https://doi.org/10.4236/psych.2021.124032"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      10.4236/psych.2021.124032
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.4236/psych.2021.124032"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Nurmi, B., Michelsen, C. S., & Norlander, T. (2019). The
                    Importance of reflection: An exploratory analysis on how
                    prospective psychotherapists learn self-determination. The
                    Open Psychology Journal, 12, 197-204. DOI:{" "}
                    <a
                      href="https://doi.org/10.2174/1874350101912010197"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      10.2174/1874350101912010197
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.2174/1874350101912010197"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Michelsen, C. S., Sundberg, P., Ekstrand, L., & Norlander,
                    T. (2018). Psychological Empowerment: Users and
                    Professionals in Psychiatric Care before and after
                    Mindfulness Treatment. Psychology, 9, 1194-1207. 
                  </p>
                  <a
                    href="https://www.scirp.org/journal/paperinformation?paperid=85063"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Abrahamsson, C., Nordling, B., Michelsen, C. S., &
                    Norlander, T. (2018). Patients’ Experiences after Cognitive
                    Behavior Group Therapy: From Anxiety to Feelings of
                    Perceived Security. Psychology, 9, 1176-1193. 
                  </p>
                  <a
                    href="https://www.scirp.org/journal/paperinformation?paperid=85052"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold tracking-tight">
                Stress and Performance
              </h2>
              <p className="text-muted-foreground">
                Our research in this area explores the relationship between
                stress, cognitive function, and human performance.
              </p>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Norlander, T., Ivarsson, B., Andersson, J., & Nordén, T.
                    (2016). Consumer Satisfaction Rating Scale: Revised for use
                    in both psychiatry and social services. Social Behavior and
                    Personality, 44, 931-942. DOI:{" "}
                    <a
                      href="https://doi.org/10.2224/sbp.2016.44.6.931"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      10.2224/sbp.2016.44.6.931
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.2224/sbp.2016.44.6.931"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Lilja, J. L., Broberg, M., Norlander, T., & Broberg, A. G.
                    (2015). Mindfulness-based cognitive therapy: Primary care
                    patients’ experiences of outcomes in everyday life and
                    relapse prevention. Psychology, 6, 464-477. DOI:{" "}
                    <a
                      href="https://doi.org/10.4236/psych.2015.64044"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      10.4236/psych.2015.64044
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.4236/psych.2015.64044"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Angantyr, K., Rimner, A., Nordén, T., & Norlander, T.
                    (2015). Primary care behavioral health (PCBH) model of
                    integrated care: Perspectives of outcome, client
                    satisfaction, and gender. Social Behavior and Personality,
                    43, 287-302. DOI:{" "}
                    <a
                      href="https://doi.org/10.2224/sbp.2015.43.2.287"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      10.2224/sbp.2015.43.2.287
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.2224/sbp.2015.43.2.287"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Ingvarsson, T., Nordén, T., & Norlander, T. (2014).
                    Mindfulness-based cognitive therapy: A case study on
                    experiences of healthy behaviors by clients in psychiatric
                    care. Open Journal of Medical Psychology, 3, 390-402. DOI:{" "}
                    <a
                      href="https://doi.org/10.4236/ojmp.2014.35041"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      10.4236/ojmp.2014.35041
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.4236/ojmp.2014.35041"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Edebol, H., Helldin, L., & Norlander, T. (2013). Measuring
                    adult attention deficit hyperactivity disorder using the
                    Quantified Behavior Test Plus. PsyCh Journal, 2, 48-62. DOI:{" "}
                    <a
                      href="https://doi.org/10.1002/pchj.17"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      10.1002/pchj.17
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.1002/pchj.17"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Edebol, H., Helldin, L., & Norlander, T. (2012). Objective
                    measures of behavior manifestations in adult ADHD and
                    differentiation from participants with bipolar II disorder,
                    borderline personality disorder, participants with
                    disconfirmed ADHD as well as normative participants.
                    Clinical Practice & Epidemiology in Mental Health, 8,
                    134-143. DOI:{" "}
                    <a
                      href="https://doi.org/10.2174/1745017901208010134"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      10.2174/1745017901208010134
                    </a>
                  </p>
                  <a
                    href="https://doi.org/10.2174/1745017901208010134"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Baccman, C., Berggren, A. W., & Norlander, T. (2012).
                    Military Capacity and Civil Adjustment: Assessments of the
                    ‘re-usable’ peacekeeping soldier for development of a
                    selection system. International Journal of Selection and
                    Assessment, 20, 171-181.
                  </p>
                  <a
                    href="https://www.diva-portal.org/smash/record.jsf?pid=diva2%3A628728&dswid=7839"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Kjellgren, A., Burkhall, H., & Norlander, T. (2011).
                    Preventing sick-leaves for sufferers of high stres-load and
                    burn-out syndrome: A pilot study combining psychotherapy
                    with the flotation tank. International Journal of Psychology
                    and Psychological Therapy, 11, 297-306.
                  </p>
                  <a
                    href="https://www.semanticscholar.org/paper/Preventing-Sick-leave-for-Sufferers-of-High-and-A-Kjellgren-Buhrkall/c3fb37cbe17a58c1fdfd0ca9158fffb4c245f524"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Norlander, T., von Schedvin, H., & Archer, T. (2005).
                    Thriving as a function of affective personality: Relation to
                    personality factors, coping strategies and stress. Anxiety,
                    Stress, and Coping, 18, 105-116.
                  </p>
                  <a
                    href="https://doi.org/10.1080/10615800500093777"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Norlander, T., Johansson, Å., & Bood, S. Å. (2005). The
                    affective personality: its relation to quality of sleep and
                    well-being. Social Behavior and Personality, 33, 709-722.
                  </p>
                  <a
                    href="https://www.ingentaconnect.com/content/sbp/sbp/2005/00000033/00000007/art00008;jsessionid=be9a0h9tt6cdj.x-ic-live-01"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Bood, S. Å., Archer, T., & Norlander, T. (2004). Affective
                    personality in relation to general personality,
                    self-reported stress, coping and optimism. Individual
                    Differences Research, 2, 26-37.
                  </p>
                  <a
                    href="https://www.researchgate.net/publication/232550909_Affective_Personality_in_Relation_to_General_Personality_Self-Reported_Stress_Coping_and_Optimism"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
                <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                  <p>
                    ➤ Norlander, T., Nordmarker, A., & Archer, T. (1998).
                    Effects of alcohol and frustration on experimental graffiti.
                    Scandinavian Journal of Psychology, 39, 201-207.
                  </p>
                  <a
                    href="https://onlinelibrary.wiley.com/doi/10.1111/1467-9450.00080"
                    className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                  >
                    Read more
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold tracking-tight">
                Other Research
              </h2>
              <div className="group rounded-lg border bg-background p-6 shadow-sm transition-all hover:bg-[#5BB7AF]">
                <p>
                  ➤ Malmström, S., Nordén, T., & Norlander, T. (2015). Is a
                  brief education enough concerning suicide and threats of
                  suicide? Evaluation of an educational initiative for the
                  emergency personnel. International Journal of Emergency Mental
                  Health and Human Resilience, 17, 661-663. 
                  {/* <a DOI:{" "}
                    href="https://doi.org/10.4172/1522-4821.1000265"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    10.4172/1522-4821.1000265
                  </a> */}
                </p>
                <a
                  href="https://www.researchgate.net/publication/283700076_Is_a_Brief_Education_Enough_Concerning_Suicide_and_Threats_of_Suicide_Evaluation_of_an_Educational_Initiative_for_the_Emergency_Personnel"
                  className="mt-4 inline-flex items-center font-medium transition-colors hover:text-accent-foreground"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read more
                  <ChevronRightIcon className="ml-2 h-4 w-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}

function ChevronRightIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}
