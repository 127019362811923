import React from "react";
import { Footer } from "./footer";

export default function AboutUs() {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <header className="bg-primary py-8 px-4 md:px-6">
        <div className="container mx-auto max-w-5xl">
          <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold text-primary-foreground text-center">
            About Us
          </h1>
        </div>
      </header>
      <main className="flex-1 py-10 md:py-6">
        <div className="container mx-auto max-w-5xl space-y-12 px-4">
          <section className="space-y-8">
            <div className="grid gap-8 md:grid-cols-2 items-center">
              <div>
                <h2 className="text-2xl md:text-3xl font-bold mb-4">
                  Our Story
                </h2>
                <p className="text-muted-foreground text-sm md:text-base">
                  My-E-Health® was founded in 2015 with the mission to
                  revolutionize the healthcare industry through innovative
                  technology. What started as a small team of passionate
                  individuals has grown into a leading provider of comprehensive
                  digital health solutions.
                </p>
                <p className="text-muted-foreground mt-4 text-sm md:text-base">
                  Our commitment to excellence and user-centric design has
                  allowed us to expand our reach, serving healthcare providers
                  and patients across the country. Today, we are proud to offer
                  a robust platform that seamlessly integrates with existing
                  systems, empowering our clients to deliver exceptional care.
                </p>
              </div>
              <div className="flex items-center justify-center">
                <iframe
                  className="w-full h-64 md:w-[560px] md:h-[315px]"
                  src="https://www.youtube.com/embed/t22SPEGtUG0?si=slEtN6ff5XfDOcDO"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl md:text-3xl font-bold mb-4">
              Certifications
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {[
                "HIPAA Compliant",
                "ISO 27001",
                "HITRUST CSF",
                "SOC 2 Type II",
              ].map((certification, index) => (
                <div
                  key={index}
                  className="bg-muted rounded-lg p-4 flex flex-col items-center justify-center hover:bg-accent hover:text-accent-foreground transition-colors"
                >
                  <BadgeCheckIcon className="w-8 h-8 mb-2" />
                  <span className="text-xs md:text-sm font-medium">
                    {certification}
                  </span>
                </div>
              ))}
            </div>
          </section>

          <section>
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Our Mission</h2>
            <p className="text-muted-foreground text-sm md:text-base">
              At My-E-Health®, our mission is to empower healthcare providers
              and patients with innovative digital solutions that enhance the
              quality of care, improve patient outcomes, and streamline
              administrative processes. We are committed to leveraging the
              latest advancements in technology to revolutionize the healthcare
              industry and make a positive impact on the lives of those we
              serve.
            </p>
          </section>

          <section>
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Our Platform</h2>
            <div className="grid gap-8 md:grid-cols-2">
              <div>
                <h3 className="text-xl md:text-2xl font-bold mb-2">
                  Comprehensive Features
                </h3>
                <ul className="space-y-2 text-muted-foreground text-sm md:text-base">
                  {[
                    "Telemedicine and virtual care",
                    "Electronic health records (EHR) integration",
                    "Appointment scheduling and management",
                    "Secure messaging and patient portal",
                    "Billing and revenue cycle management",
                  ].map((feature, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <CheckIcon className="w-4 h-4 md:w-5 md:h-5 text-primary" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-xl md:text-2xl font-bold mb-2">
                  Tailored Solutions
                </h3>
                <p className="text-muted-foreground text-sm md:text-base">
                  Our platform is designed to be highly customizable, allowing
                  healthcare providers to tailor the solution to their specific
                  needs and workflows. We work closely with our clients to
                  understand their unique challenges and requirements, ensuring
                  that our platform delivers the optimal user experience and
                  drives tangible results.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Our Services</h2>
            <div className="grid gap-8 md:grid-cols-2">
              <div>
                <h3 className="text-xl md:text-2xl font-bold mb-2">
                  Consulting and Implementation
                </h3>
                <p className="text-muted-foreground text-sm md:text-base">
                  Our team of experienced healthcare IT professionals provides
                  comprehensive consulting and implementation services to ensure
                  a seamless transition to our platform. We work closely with
                  our clients to understand their workflows, identify pain
                  points, and develop a tailored implementation plan that
                  minimizes disruption and maximizes the value of our solutions.
                </p>
              </div>
              <div>
                <h3 className="text-xl md:text-2xl font-bold mb-2">
                  Training and Support
                </h3>
                <p className="text-muted-foreground text-sm md:text-base">
                  We understand that the success of our platform depends on the
                  ability of our clients to effectively utilize its features and
                  functionalities. That's why we offer comprehensive training
                  and ongoing support to ensure that healthcare providers and
                  their staff are empowered to get the most out of our
                  solutions. Our dedicated support team is available 24/7 to
                  address any questions or concerns that may arise.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl md:text-3xl font-bold mb-4">
              Privacy and Integrity
            </h2>
            <p className="text-muted-foreground text-sm md:text-base">
              At My-E-Health®, we are committed to protecting the privacy and
              integrity of the sensitive healthcare data entrusted to us. We
              adhere to the highest industry standards and regulations,
              including HIPAA, HITRUST, and SOC 2 compliance, to ensure the
              security and confidentiality of our clients' information. Our
              platform is designed with robust security features, including
              end-to-end encryption, multi-factor authentication, and advanced
              access controls, to safeguard against unauthorized access and data
              breaches.
            </p>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
}

function BadgeCheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
      <path d="m9 12 2 2 4-4" />
    </svg>
  );
}

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}
