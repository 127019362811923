import React from "react";
import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import { Footer } from "./footer";

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  heading: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

export type CareGiverProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const CareGiver = (props: CareGiverProps) => {
  const { heading, description, buttons, image } = {
    ...CareGiverDefaults,
    ...props,
  } as Props;
  return (
    <main>
      <section className="px-[5%] py-16 md:py-24 lg:py-28 bg-[#E8F5F4]">
        <div className="container">
          <div className="text-center mb-12">
            <h1 className="mb-5 text-6xl font-bold md:mb-6 md:text-9xl lg:text-10xl">
              {heading}
            </h1>
            <p className="md:text-md">{description}</p>
          </div>
          <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-2 lg:gap-x-20 lg:items-center mb-16">
            <div className="aspect-video overflow-hidden rounded-lg object-cover image-CG1" />
            <div>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-4">
                Do you want to make a difference?
              </h2>
              <p className="text-muted-foreground mb-4">
                At My-E-Health we work proactively with a method that early
                identifies causes and treats symptoms using scientifically
                proven tools based on mixed-methods CBT. Using a style that
                works for your patient, whether it is Gestalt, Psychodynamic,
                DBT, ACT, CBT, Positive Therapy or a mixture of all. Online
                counselling is an effective form of treatment which makes
                healthcare more accessible to your customers. My-E-Health works
                primarily with preventing mental illness and rehabilitation, but
                mind, body & soul are very important and physician services are
                provided as well.
              </p>
              <ul className="space-y-2 text-muted-foreground mb-6">
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Scientifically proven tools</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Personalized therapy styles</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Comprehensive mental health care</span>
                </li>
              </ul>
              <div className="mt-6 flex gap-x-4 md:mt-8">
                {buttons.map((button, index) => (
                  <Button key={index} {...button}>
                    {button.title}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <section className="w-full py-12 md:py-24 lg:py-32">
            <div className="container px-4 md:px-6">
              <div className="mx-auto max-w-5xl">
                <div className="space-y-4 text-center">
                  <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                    A Unique Platform
                  </h2>
                  <p className="text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                    Discover how our comprehensive services can transform your
                    organization's health and well-being.
                  </p>
                </div>
                <div className="mx-auto grid max-w-5xl items-center gap-12 py-12 lg:grid-cols-2">
                  <div className="space-y-6">
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">
                        Advanced Digital Tools
                      </h3>
                      <p className="text-muted-foreground">
                        My-E-Health offers a digitally interactive platform with
                        many tools where you as a healthcare provider can use
                        our advanced ecosystem to provide excellent care,
                        continuity and trust, and to treat and monitor your
                        customers' mental health and wellbeing.
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">Proactive Approach</h3>
                      <p className="text-muted-foreground">
                        We work proactively with a method that early identifies
                        causes and treats symptoms using scientifically proven
                        tools based on mixed-methods CBT.
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">
                        Accessible Online Counselling
                      </h3>
                      <p className="text-muted-foreground">
                        Online counselling is an effective form of treatment
                        which makes healthcare more accessible to your
                        customers.
                      </p>
                    </div>
                  </div>
                  <div className="grid gap-6">
                    <div>
                      <div className="aspect-video overflow-hidden rounded-lg object-cover image-CG2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" py-12 md:py-24 lg:py-32">
            <div className="container grid gap-12 md:grid-cols-2 lg:gap-24">
              <div className="space-y-6">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                  Professional and Flexible
                </h2>
                <div className="grid gap-4 text-muted-foreground">
                  <p>
                    My-E-Health works primarily with preventing mental illness
                    and rehabilitation, but mind, body & soul are very important
                    and physician services are provided as well.
                  </p>
                  <p>
                    We offer a digitally interactive platform with many tools
                    where you as a healthcare provider can use our advanced
                    ecosystem to provide excellent care, continuity and trust,
                    and to treat and monitor your customers' mental health and
                    wellbeing.
                  </p>
                  <p>
                    With My-E-Health, you can stay ahead of potential health
                    issues, identify areas for improvement, and take actionable
                    steps to optimize your overall well-being.
                  </p>
                </div>
              </div>
              <div className="mx-auto aspect-video overflow-hidden rounded-xl object-cover object-center image-CG3" />
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export const CareGiverDefaults: CareGiverProps = {
  heading: "CareGiver",
  description: "",
  buttons: [],
  image: {
    src: "https://relume-assets.s3.amazonaws.com/placeholder-image.svg",
    alt: "Placeholder image",
  },
};

CareGiver.displayName = "CareGiver";

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}
