import React from "react";
import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import { Footer } from "./footer";

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  heading: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

export type IndividualProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Individual = (props: IndividualProps) => {
  const { heading, description, buttons, image } = {
    ...IndividualDefaults,
    ...props,
  } as Props;
  return (
    <main>
      <section className="px-[5%] py-16 md:py-24 lg:py-28 bg-[#E8F5F4]">
        <div className="container">
          <div className="text-center mb-12">
            <h1 className="mb-5 text-6xl font-bold md:mb-6 md:text-9xl lg:text-20xl">
              {heading}
            </h1>
            <p className="md:text-md">{description}</p>
          </div>
          <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-2 lg:gap-x-20 lg:items-center mb-16">
            <div>
              <div>
                <div className="aspect-video overflow-hidden rounded-lg object-cover image-indy" />
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-4">
                It's all about you
              </h2>
              <p className="text-muted-foreground mb-4">
                My-E-Health is an e-health platform where you as a customer
                receive professional help to prevent and treat both mental and
                physical illnesses. The online assessments are about you, your
                thoughts, your feelings, your beliefs and your well-being. All
                personal data is kept strictly confidential. There are several
                self-help tools that are available 24/7. You do your assessments
                and receive your results in the platform, you also do your
                bookings with the health team direct online in the calendar. A
                unique digital meeting-room makes full interaction between the
                customer and the health coach possible.
              </p>
              <div className="mt-6 flex gap-x-4 md:mt-8">
                {buttons.map((button, index) => (
                  <Button key={index} {...button}>
                    {button.title}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <section className="w-full py-12 md:py-24 lg:py-32">
            <div className="container px-4 md:px-6">
              <div className="mx-auto max-w-5xl">
                <div className="space-y-4 text-center">
                  <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                    What we provide
                  </h2>
                  <p className="text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                    Convenient, affordable, private online counselling.
                    Accessible anytime, anywhere with direct access to your
                    caregiver's booking calendar. Your health coach is available
                    for you when you need that extra help to see your issues
                    clearly and find a good path to handle them.
                  </p>
                </div>
                <div className="mx-auto grid max-w-5xl items-center gap-12 py-12 lg:grid-cols-2">
                  <div className="space-y-6">
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">
                        Comprehensive Support
                      </h3>
                      <p className="text-muted-foreground">
                        We do not only treat the symptoms but also find the
                        causes. You can choose your own health coach for a
                        valuable continuity that creates trust. All health
                        coaches are licensed and accredited by My-E-Health and
                        continuously trained to uphold our high standards.
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">Health Integration</h3>
                      <p className="text-muted-foreground">
                        A healthy mind, body & soul are important to us. Your
                        sustainable health is correlated to your self-awareness
                        and personal engagement. Together your My-E-Health coach
                        will be there and guide you throughout your well-being
                        journey.
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">Growth and Success</h3>
                      <p className="text-muted-foreground">
                        This in turn will lead to both personal growth and inner
                        success.
                      </p>
                    </div>
                  </div>
                  <div className="grid gap-6">
                    <div>
                      <div className="aspect-video overflow-hidden rounded-lg object-cover image-indy2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" py-12 md:py-24 lg:py-32">
            <div className="container grid gap-12 md:grid-cols-2 lg:gap-24">
              <div className="space-y-6">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                  How it works
                </h2>
                <div className="grid gap-4 text-muted-foreground">
                  <p>
                    Depending upon your contract, assessments and coaching are
                    usually done four-times a year to incorporate seasonal
                    changes and frequent enough to create continuity, continuity
                    in care and trust.
                  </p>
                  <p>
                    If your assessments indicate changes from your mean
                    wellness, frustration or stress, My-E-Health will help you
                    to identify them and assist you in finding the right
                    coping-skills to neutralize them as soon as possible, and
                    before they become symptomatic ones.
                  </p>
                  <p>
                    When you need someone to talk to your counselor is only a
                    click away. You may be eligible for a remittance to a set of
                    support sessions with other caregivers too, this is handled
                    online together with health coaches.
                  </p>
                </div>
              </div>
              <div>
                <div className="aspect-video overflow-hidden rounded-lg object-cover image-indy3" />
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export const IndividualDefaults: IndividualProps = {
  heading: "Individual",
  description: "",
  buttons: [],
  image: {
    src: "https://relume-assets.s3.amazonaws.com/placeholder-image.svg",
    alt: "Placeholder image",
  },
};

Individual.displayName = "Individual";

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}
