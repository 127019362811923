import React from "react";
import "./index.css";
import { FaCertificate, FaGlobe, FaShieldAlt } from "react-icons/fa";

export default function Component() {
  return (
    <section className="relative w-full py-12 md:py-24 lg:py-32 hero-bg1 bg-cover bg-center bg-no-repeat">
      <div className="relative container px-4 md:px-6 ">
        <div className="flex flex-col lg:flex-row bg-white bg-opacity-90 rounded-lg shadow-lg">
          {/* First Div: List of certifications */}
          <div className="flex-1 p-6 lg:p-8">
            <div className="space-y-6">
              <div className="inline-block rounded-lg bg-gray-200 px-4 py-2 text-xl font-semibold mb-3 text-gray-800">
                Our Compliance and Certifications
              </div>
              <div className="flex mt-4 flex-col pr-5 md:flex-row md:space-x-4">
                {/* Certifications list */}
                <ul className="text-lg lg:text-xl text-gray-800 space-y-6 md:space-y-4 lg:space-y-4">
                  <li className="flex items-center space-x-4 pb-3">
                    <FaCertificate className="text-[#5BB7AF]"                   
                     size={24}
                    sm:size={28}
                    md:size={32}
                    lg:size={36} />
                    <span className="font-semibold text-lg lg:text-xl ml-4">
                      Certified & Accredited
                    </span>
                  </li>
                  <li className="flex items-center space-x-4 pb-3">
                    <FaGlobe className="text-[#5BB7AF]"                
                    size={24}
                    sm:size={28}
                    md:size={32}
                    lg:size={36} />
                    <span className="font-semibold text-lg lg:text-xl ml-4">
                      Trusted Worldwide
                    </span>
                  </li>
                  <li className="flex items-center space-x-4 pb-3">
                    <FaShieldAlt className="text-[#5BB7AF]"                    
                    size={24}
                    sm:size={28}
                    md:size={32}
                    lg:size={36} />
                    <span className="font-semibold text-lg lg:text-xl ml-4">
                      Fully Compliant
                    </span>
                  </li>
                </ul>

                {/* Medical Devices and other certifications */}
                <div className="space-y-6 md:pl-3 lg:pl-0 mt-8 md:mt-0">
                  <div className="flex items-center gap-3 pb-3">
                    <HospitalIcon className="h-6 w-6 text-[#5BB7AF]" />
                    <div>
                      <div className="font-semibold text-lg lg:text-xl">
                        Medical Device Class-1
                      </div>
                      <div className="text-sm text-gray-600">
                        Registered and Approved
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-3  pb-3">
                    <LockIcon className="h-6 w-6 text-[#5BB7AF]" />
                    <div>
                      <div className="font-semibold text-lg lg:text-xl">
                        DSPT IT Security Audits
                      </div>
                      <div className="text-sm text-gray-600">
                        Passed and Approved
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 pb-3">
                    <CheckIcon className="h-6 w-6 text-[#5BB7AF]" />
                    <div>
                      <div className="font-semibold text-lg lg:text-xl">
                        CE and CA Approved
                      </div>
                      <div className="text-sm text-gray-600">
                        Meets Regulatory Standards
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 pb-3">
                    <CompassIcon className="h-6 w-6 text-[#5BB7AF]" />
                    <div>
                      <div className="font-semibold text-lg lg:text-xl">
                        GDPR and HIPAA Compliant
                      </div>
                      <div className="text-sm text-gray-600">
                        Protecting User Data
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 pb-3">
                    <SearchIcon className="h-6 w-6 text-[#5BB7AF]" />
                    <div>
                      <div className="font-semibold text-lg lg:text-xl">
                        Clinical Trials and Research
                      </div>
                      <div className="text-sm text-gray-600">
                        Evidence-Based Solutions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Third Div: Paragraph */}
          <div className="flex-1 p-6 lg:pt-[120px] lg:p-8">
            <p className="text-gray-700 text-base md:text-lg lg:text-xl">
              My-E-Health® is a top provider of e-health solutions, using AI,
              psychometrics, biometrics, and expert online support to tackle
              health issues like stress, anxiety, low motivation, depression,
              and burnout. Our proactive tools help identify and address these
              challenges early.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

// Icons unchanged as per your request

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
}

function CompassIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m16.24 7.76-1.804 5.411a2 2 0 0 1-1.265 1.265L7.76 16.24l1.804-5.411a2 2 0 0 1 1.265-1.265z" />
      <circle cx="12" cy="12" r="10" />
    </svg>
  );
}

function HospitalIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 6v4" />
      <path d="M14 14h-4" />
      <path d="M14 18h-4" />
      <path d="M14 8h-4" />
      <path d="M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2" />
      <path d="M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18" />
    </svg>
  );
}

function LockIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
      <path d="M7 11V7a5 5 0 0 1 10 0v4" />
    </svg>
  );
}

function SearchIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  );
}
