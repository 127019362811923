import React from "react";

export default function Cookies() {
  return (
    <div className="bg-background text-foreground">
      <header className="bg-primary text-primary-foreground py-6 px-4 md:px-6">
        <div className="container mx-auto max-w-4xl">
          <h1 className="text-3xl font-bold">Cookies Policy</h1>
        </div>
      </header>
      <main className="py-12 px-4 md:px-6">
        <div className="container mx-auto max-w-4xl space-y-8">
          <div>
            <h2 className="text-2xl font-bold">What are Cookies?</h2>
            <p className="mt-4 text-muted-foreground">
              Cookies are small text files that are stored on your device when
              you visit a website. They help the website remember your actions
              and preferences (such as login, language, font size, and other
              display preferences) over a period of time, so you don't have to
              keep re-entering them whenever you come back to the site or browse
              from one page to another.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold">How We Use Cookies</h2>
            <p className="mt-4 text-muted-foreground">
              We use cookies to enhance your user experience, analyze site
              traffic, and personalize content. The cookies we use fall into the
              following categories:
            </p>
            <ul className="mt-4 space-y-4">
              <li>
                <h3 className="text-lg font-bold">Essential Cookies</h3>
                <p className="text-muted-foreground">
                  These cookies are necessary for the website to function and
                  cannot be switched off in our systems. They are usually only
                  set in response to actions made by you, such as setting your
                  privacy preferences, logging in, or filling in forms.
                </p>
              </li>
              <li>
                <h3 className="text-lg font-bold">Analytical Cookies</h3>
                <p className="text-muted-foreground">
                  These cookies allow us to count visits and traffic sources, so
                  we can measure and improve the performance of our site. They
                  help us understand which pages are the most and least popular
                  and how visitors move around the site.
                </p>
              </li>
              <li>
                <h3 className="text-lg font-bold">Marketing Cookies</h3>
                <p className="text-muted-foreground">
                  These cookies may be set through our site by our advertising
                  partners. They may be used by those companies to build a
                  profile of your interests and show you relevant adverts on
                  other sites.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-bold">
              How Can I Control Cookies on My Browser?
            </h2>
            <p className="mt-4 text-muted-foreground">
              You have the right to decide whether to accept or reject cookies.
              You can exercise your cookie rights by setting your preferences in
              the Cookie Consent Manager. The Cookie Consent Manager allows you
              to select which categories of cookies you accept or reject.
              Essential cookies cannot be rejected as they are strictly
              necessary to provide you with services.
            </p>
            <p className="mt-4 text-muted-foreground">
              The Cookie Consent Manager can be found in the notification banner
              and on our website. If you choose to reject cookies, you may still
              use our website though your access to some functionality and areas
              of our website may be restricted. You may also set or amend your
              web browser controls to accept or refuse cookies.
            </p>
            <p className="mt-4 text-muted-foreground">
              As the means by which you can refuse cookies through your web
              browser controls vary from browser to browser, you should visit
              your browser's help menu for more information. The following is
              information about how to manage cookies on the most popular
              browsers:
            </p>
            <ul className="mt-4 space-y-2 text-muted-foreground">
              <li>• Chrome</li>
              <li>• Internet Explorer</li>
              <li>• Firefox</li>
              <li>• Safari</li>
              <li>• Edge</li>
              <li>• Opera</li>
            </ul>
            <p className="mt-4 text-muted-foreground">
              In addition, most advertising networks offer you a way to opt out
              of targeted advertising. If you would like to find out more
              information, please visit:
            </p>
            <ul className="mt-4 space-y-2 text-muted-foreground">
              <li>• Digital Advertising Alliance</li>
              <li>• Digital Advertising Alliance of Canada</li>
              <li>• European Interactive Digital Advertising Alliance</li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-bold">
              What about other tracking technologies, like web beacons?
            </h2>
            <p className="mt-4 text-muted-foreground">
              Cookies are not the only way to recognize or track visitors to a
              website. We may use other, similar technologies from time to time,
              like web beacons (sometimes called "tracking pixels" or "clear
              gifs"). These are tiny graphics files that contain a unique
              identifier that enables us to recognize when someone has visited
              our Website or opened an email including them. This allows us, for
              example, to monitor the traffic patterns of users from one page
              within a website to another, to deliver or communicate with
              cookies, to understand whether you have come to the website from
              an online advertisement displayed on a third-party website, to
              improve site performance, and to measure the success of email
              marketing campaigns. In many instances, these technologies are
              reliant on cookies to function properly, and so declining cookies
              will impair their functioning.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold">
              Do you use Flash cookies or Local Shared Objects?
            </h2>
            <p className="mt-4 text-muted-foreground">
              Websites may also use so-called "Flash Cookies" (also known as
              Local Shared Objects or "LSOs") to, among other things, collect
              and store information about your use of our services, fraud
              prevention, and for other site operations.
            </p>
            <p className="mt-4 text-muted-foreground">
              If you do not want Flash Cookies stored on your computer, you can
              adjust the settings of your Flash player to block Flash Cookies
              storage using the tools contained in the Website Storage Settings
              Panel. You can also control Flash Cookies by going to the Global
              Storage Settings Panel and following the instructions (which may
              include instructions that explain, for example, how to delete
              existing Flash Cookies (referred to "information" on the
              Macromedia site), how to prevent Flash LSOs from being placed on
              your computer without your being asked, and (for Flash Player 8
              and later) how to block Flash Cookies that are not being delivered
              by the operator of the page you are on at the time).
            </p>
            <p className="mt-4 text-muted-foreground">
              Please note that setting the Flash Player to restrict or limit
              acceptance of Flash Cookies may reduce or impede the functionality
              of some Flash applications, including. potentially, Flash
              applications used in connection with our services or online
              content.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold">
              Do you serve targeted advertising?
            </h2>
            <p className="mt-4 text-muted-foreground">
              Third parties may serve cookies on your computer or mobile device
              to serve advertising through our Website. These companies may use
              information about your visits to this and other websites in order
              to provide relevant advertisements about goods and services that
              you may be interested in. They may also employ technology that is
              used to measure the effectiveness of advertisements. They can
              accomplish this by using cookies or web beacons to collect
              information about your visits to this and other sites in order to
              provide relevant advertisements about goods and services of
              potential interest to you. The information collected through this
              process does not enable us or them to identify your name, contact
              details, or other details that directly identify you unless you
              choose to provide these.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold">
              How often will you update this Cookie Policy?
            </h2>
            <p className="mt-4 text-muted-foreground">
              We may update this Cookie Policy from time to time in order to
              reflect, for example, changes to the cookies we use or for other
              operational, legal, or regulatory reasons. Please therefore
              revisit this Cookie Policy regularly to stay informed about our
              use of cookies and related technologies.
            </p>
            <p className="mt-4 text-muted-foreground">
              The date at the top of this Cookie Policy indicates when it was
              last updated.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold">
              Where can I get further information?
            </h2>
            <p className="mt-4 text-muted-foreground">
              If you have any questions about our use of cookies or other
              technologies, please email us at info@my-e-health.com or by post
              to:
            </p>
            <address className="mt-4 text-muted-foreground">
              JAC International
              <br />
              Sweden
            </address>
          </div>
          <div>
            <h2 className="text-2xl font-bold">COOKIE POLICY</h2>
            <p className="mt-4 text-muted-foreground">
              Last updated July 30, 2024
            </p>
            <p className="mt-4 text-muted-foreground">
              This Cookie Policy explains how JAC International ("Company,"
              "we," "us," and "our") uses cookies and similar technologies to
              recognize you when you visit our website at{" "}
              <a
                href="https://www.my-e-health.com"
                className="text-primary underline"
              >
                https://www.my-e-health.com
              </a>{" "}
              ("Website"). It explains what these technologies are and why we
              use them, as well as your rights to control our use of them.
            </p>
            <p className="mt-4 text-muted-foreground">
              In some cases we may use cookies to collect personal information,
              or that becomes personal information if we combine it with other
              information.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}
