import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";

type ImageProps = {
  className: string;
  alt?: string;
};

type Props = {
  heading: string;
  logos: ImageProps[];
  logos2: ImageProps[];
};

export type PartnersProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Partners = (props: PartnersProps) => {
  const { heading, logos, logos2 } = {
    ...PartnersDefaults,
    ...props,
  } as Props;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [visibleItems, setVisibleItems] = useState(7); // Default to 7 for large screens
  const [isMobile, setIsMobile] = useState(false);

  const updateVisibleItems = (width: number) => {
    if (width >= 1280) {
      setVisibleItems(7); // 7 logos on extra large screens
    } else if (width >= 1024) {
      setVisibleItems(5); // 5 logos on large screens
    } else if (width >= 768) {
      setVisibleItems(4); // 4 logos on medium screens
    } else {
      setVisibleItems(2); // 2 logos on small screens
    }
  };

  const handleResize = () => {
    const width = window.innerWidth;
    setScreenWidth(width);
    updateVisibleItems(width);

    // Check if mobile
    setIsMobile(width <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="py-12 md:py-16 lg:py-12 lg:px-[10%]">
      <div className="container">
        <p className="mx-auto mb-6 w-full max-w-lg text-center text-base font-bold leading-[1.2] md:mb-8 md:text-md md:leading-[1.2]">
          {heading}
        </p>

        <div className="flex flex-wrap items-center justify-center gap-x-8 gap-y-4 pb-2 pt-4 md:pt-2">
          {/* {isMobile ? (
            logos2.map((logo, index) => (
              <div
                key={index}
                className={`${logo.className} bg-no-repeat bg-center bg-contain max-h-12 md:max-h-14 w-24 h-12 md:w-28 md:h-14`}
                role="img"
                aria-label={logo.alt}
              ></div>
            ))
          ) : (
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={3000}
              transitionTime={500}
              swipeable={true}
              emulateTouch={true}
              showIndicators={false}
              className="partners-carousel"
              centerMode={true}
              centerSlidePercentage={100 / visibleItems}
              dynamicHeight={false}
            >
              {logos.map((logo, index) => (
                <div
                  key={index}
                  className={`sponsor-logo ${logo.className} flex justify-center items-center`}
                  aria-label={logo.alt}
                />
              ))}
            </Carousel>
          )} */}
          {logos.map((logo, index) => (
            <div
              key={index}
              className={`${logo.className} bg-no-repeat bg-center bg-contain max-h-12 md:max-h-14 w-24 h-12 md:w-28 md:h-14`}
              role="img"
              aria-label={logo.alt}
            ></div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const PartnersDefaults: PartnersProps = {
  heading: "Our Clients & Partners Worldwide",
  logos: [
    { className: "sponsor-1", alt: "Sponsor logo 1" },
    { className: "sponsor-2", alt: "Sponsor logo 2" },
    { className: "sponsor-3", alt: "Sponsor logo 3" },
    { className: "sponsor-4", alt: "Sponsor logo 4" },
    { className: "sponsor-5", alt: "Sponsor logo 5" },
    { className: "sponsor-6", alt: "Sponsor logo 6" },
    { className: "sponsor-7", alt: "Sponsor logo 7" },
    { className: "sponsor-8", alt: "Sponsor logo 8" },
    { className: "sponsor-9", alt: "Sponsor logo 9" },
    { className: "sponsor-10", alt: "Sponsor logo 10" },
    { className: "sponsor-11", alt: "Sponsor logo 11" },
    { className: "sponsor-12", alt: "Sponsor logo 12" },
    { className: "sponsor-13", alt: "Sponsor logo 13" },
    { className: "sponsor-14", alt: "Sponsor logo 14" },
    { className: "sponsor-15", alt: "Sponsor logo 15" },
    { className: "sponsor-16", alt: "Sponsor logo 16" },
    { className: "sponsor-17", alt: "Sponsor logo 17" },
    { className: "sponsor-18", alt: "Sponsor logo 18" },
    { className: "sponsor-19", alt: "Sponsor logo 19" },
    { className: "sponsor-20", alt: "Sponsor logo 20" },
  ],

  logos2: [
    { className: "sponsor-1", alt: "Sponsor logo 1" },
    { className: "sponsor-2", alt: "Sponsor logo 2" },
    { className: "sponsor-3", alt: "Sponsor logo 3" },
    { className: "sponsor-4", alt: "Sponsor logo 4" },
    { className: "sponsor-5", alt: "Sponsor logo 5" },
    { className: "sponsor-6", alt: "Sponsor logo 6" },
    { className: "sponsor-7", alt: "Sponsor logo 7" },
    { className: "sponsor-8", alt: "Sponsor logo 8" },
    { className: "sponsor-9", alt: "Sponsor logo 9" },
     { className: "sponsor-10", alt: "Sponsor logo 10" },
    { className: "sponsor-11", alt: "Sponsor logo 11" },
    { className: "sponsor-12", alt: "Sponsor logo 12" },
    { className: "sponsor-13", alt: "Sponsor logo 13" },
  ],
};

Partners.displayName = "Partners";
