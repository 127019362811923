import React from "react";
import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import "./index.css";
import { Footer } from "./footer";

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  heading: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

export type SportProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Sport = (props: SportProps) => {
  const { heading, description, buttons, image } = {
    ...SportDefaults,
    ...props,
  } as Props;
  return (
    <main>
      <section className="px-[5%] py-16 md:py-24 lg:py-28 bg-[#E8F5F4]">
        <div className="container">
          <div className="text-center mb-12">
            <h1 className="mb-5 text-6xl font-bold md:mb-6 md:text-9xl lg:text-10xl">
              {heading}
            </h1>
            <p className="md:text-md">{description}</p>
          </div>
          <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-2 lg:gap-x-20 lg:items-center mb-16">
            <div>
              <div>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/5Ml6ekQYDs4?si=ktpLfr4ycNnspVz2"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-4">
                Why Choose My-E-Health Professional Sport?
              </h2>
              <p className="text-muted-foreground mb-4">
                Real-time observe and track your team's and individual's
                physical readiness through our user-friendly platform. Generate
                detailed reports to analyze performance metrics, track progress,
                and tailor training strategies for optimal athletic achievement.
              </p>
              <ul className="space-y-2 text-muted-foreground mb-6">
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Physical Readiness</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Cognitive Readiness</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Match Readiness</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Garmin Partner</span>
                </li>
              </ul>
              <div className="mt-6 flex gap-x-4 md:mt-8">
                {buttons.map((button, index) => (
                  <Button key={index} {...button}>
                    {button.title}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <section className="w-full py-12 md:py-24 lg:py-32">
            <div className="container px-4 md:px-6">
              <div className="mx-auto max-w-5xl">
                <div className="space-y-4 text-center">
                  <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                    Platform
                  </h2>
                  <p className="text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                    Powerful performance analysis. Features include Calendar,
                    Online meeting, Vital analysis dashboard, AI Reports, and
                    more.
                  </p>
                </div>
                <div className="mx-auto grid max-w-5xl items-center gap-12 py-12 lg:grid-cols-2">
                  <div className="space-y-6">
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">Live Data Tracking</h3>
                      <p className="text-muted-foreground">
                        Monitor individual and team activity statuses in
                        real-time using the My-E-Health and Garmin Connect apps.
                        Live data can be viewed on multiple devices at any one
                        time.
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">Rehab</h3>
                      <p className="text-muted-foreground">
                        Online rehab sessions with the entire team, featuring
                        tools such as online conference meeting, whiteboard,
                        notepad, and more.
                      </p>
                    </div>
                  </div>
                  <div className="grid gap-6">
                    <div className="aspect-video overflow-hidden rounded-lg object-cover image-SP1" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-12 md:py-24 lg:py-32">
            <div className="container grid gap-12 md:grid-cols-2 lg:gap-24">
              <div className="space-y-6">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                  How it works?
                </h2>
                <div className="grid gap-4 text-muted-foreground">
                  <p>
                    Depending upon your contract, assessments and coaching are
                    usually done weekly to incorporate changes and frequent
                    enough to create continuity, continuity in care and trust.
                    If your assessments indicate changes from your mean
                    wellness, frustration or stress, My-E-Health will help you
                    to identify them and assist you in finding the right
                    coping-skills to neutralize them as soon as possible, and
                    before they become symptomatic ones. When you need someone
                    to talk to, your counsellor is only a click away. You may be
                    eligible for a remittance to a set of support sessions with
                    other caregivers too; this is handled online together with
                    health coaches.
                  </p>
                </div>
              </div>
              <div>
                <div className="aspect-video overflow-hidden rounded-lg object-cover image-SP2" />
              </div>
            </div>
          </section>
          <section className="w-full py-12 md:py-24 lg:py-32">
            <div className="container px-4 md:px-6">
              <div className="mx-auto max-w-5xl">
                <div className="mx-auto grid max-w-5xl items-center gap-12 py-12 lg:grid-cols-2">
                  <div className="grid gap-6">
                    <div>
                      <div className="aspect-video overflow-hidden rounded-lg object-cover image-placeholder" />
                    </div>
                  </div>
                  <div className="space-y-4 text-center lg:text-left">
                    <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                      What we provide
                    </h2>
                    <p className="grid gap-4 text-muted-foreground">
                      Convenient, affordable, private online counselling.
                      Accessible anytime, anywhere with direct access to your
                      caregiver's booking calendar. Your health coach is
                      available for you when you need that extra help to see
                      your issues clearly and find a good path to handle them.
                      We do not only treat the symptoms but also find the
                      causes. You can choose your own health coach for a
                      valuable continuity that creates trust. All health coaches
                      are licensed and accredited by My-E-Health and
                      continuously trained to uphold our high standards. A
                      healthy mind, body & soul are important to us. Your
                      sustainable health is correlated to your self-awareness
                      and personal engagement. Together your My-E-Health coach
                      will be there and guide you throughout your well-being
                      journey. This in turn will lead to both personal growth
                      and inner success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export const SportDefaults: SportProps = {
  heading: "Sport",
  description: "",
  buttons: [],
  image: {
    src: "https://relume-assets.s3.amazonaws.com/placeholder-image.svg",
    alt: "Placeholder image",
  },
};

Sport.displayName = "Sport";

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}
