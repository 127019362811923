import React from "react";

const TermsAndConditions: React.FC = () => (
  <div className="max-w-4xl mx-auto p-6 bg-white text-gray-800">
    <h1 className="text-3xl font-bold mb-4">
      Standard Service Terms and Conditions
    </h1>

    <h2 className="text-2xl font-semibold mt-8 mb-4">BACKGROUND</h2>
    <p className="mb-4">
      These Terms and Conditions are the standard terms for the provision of
      services by My-E-Health trading as My-E-Health, a Private Limited Company
      registered in England under number 14217826 whose registered address is
      Lowin House Tregolls Rd. Truro Cornwall TR1 2NA and whose main trading
      address is Rödklintsgatan 2B Tygelsjö Sweden.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">
      Definitions and Interpretation
    </h2>
    <p className="mb-4">
      In these Terms and Conditions, unless the context otherwise requires, the
      following expressions have the following meanings:
    </p>
    <ul className="list-disc pl-8 mb-4 space-y-2">
      <li>
        <strong>“Business Day”</strong> means any day other than a Saturday,
        Sunday, or bank holiday;
      </li>
      <li>
        <strong>“Calendar Day”</strong> means any day of the year;
      </li>
      <li>
        <strong>“Contract”</strong> means the contract for the provision of
        Services as explained in Clause 3;
      </li>
      <li>
        <strong>“Deposit”</strong> means an advance payment made to Us under
        sub-Clause 5.5;
      </li>
      <li>
        <strong>“Intellectual Property Rights”</strong> means copyright (and
        related rights), designs, patents, trademarks, and all other
        intellectual property rights that may exist in anything that We may
        create or produce as part of the Services. This includes all such rights
        whether they are registered or unregistered and the rights to apply for
        renewals or extensions of those rights (where relevant);
      </li>
      <li>
        <strong>“Month”</strong> means a calendar month;
      </li>
      <li>
        <strong>“Price”</strong> means the price payable for the Services;
      </li>
      <li>
        <strong>“Services”</strong> means the services which are to be provided
        by Us to you as specified in your Order (and confirmed in Our Order
        Confirmation);
      </li>
      <li>
        <strong>“Special Price”</strong> means a special offer price payable for
        Services which We may offer from time to time;
      </li>
      <li>
        <strong>“Order”</strong> means your order for the Services as invoiced;
      </li>
      <li>
        <strong>“Order Confirmation”</strong> means Our acceptance and
        confirmation of your Order as described in Clause 3;
      </li>
      <li>
        <strong>“We/Us/Our”</strong> means Online health and wellness support
        via assessments, AI tools, and video support, if available, trading as
        My-E-Health, a Private Limited Company registered in England under
        number 14217826, whose registered address is Lowin House Tregolls Rd.
        Truro Cornwall TR1 2NA and whose main trading address is Rödklintsgatan
        2B, 218 73 Tygelsjö, Sweden.
      </li>
    </ul>

    <p className="mb-4">
      Each reference in these Terms and Conditions to “writing” and any similar
      expression includes electronic communications, whether sent by e-mail,
      within your message centre, fax, or other means.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">Information About Us</h2>
    <p className="mb-4">
      My-E-Health U.K. Ltd, trading as My-E-Health, is a Private Limited Company
      registered in England under number 14217826, whose registered address is
      Lowin House Tregolls Road, Truro, Cornwall TR1 2NA, and whose main trading
      address is Rödklintsgatan 2B, 218 73 Tygelsjö, Sweden. Our VAT number is
      SE556592314001. We are regulated by Medical Device Registration CE/CA DSPT
      Audit & GDPR.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">The Contract</h2>
    <p className="mb-4">
      These Terms and Conditions govern the sale and provision of Services by Us
      and will form the basis of the Contract between Us and you. Before
      submitting an Order, please ensure that you have read these Terms and
      Conditions carefully. If you are unsure about any part of these Terms and
      Conditions, please ask Us for clarification.
    </p>
    <p className="mb-4">
      Nothing provided by Us, including but not limited to sales and marketing
      literature, price lists, and other documents, constitutes a contractual
      offer capable of acceptance. Your Order constitutes a contractual offer
      that We may, at our discretion, accept.
    </p>
    <p className="mb-4">
      A legally binding contract between Us and you will be created upon Our
      acceptance of your Order, indicated by Our Order Confirmation. Order
      Confirmations will be provided in writing.
    </p>
    <p className="mb-4">
      We shall ensure that the following information is given or made available
      to you prior to the formation of the Contract between Us and you, save for
      where such information is already apparent from the context of the
      transaction:
    </p>
    <ul className="list-disc pl-8 mb-4 space-y-2">
      <li>The main characteristics of the Services;</li>
      <li>
        Our identity (set out above in Clause 2) and contact details (as set out
        below in Clause 12);
      </li>
      <li>
        The total Price for the Services including taxes or if the nature of the
        Services is such that the Price cannot be calculated in advance the
        manner in which it will be calculated;
      </li>
      <li>
        The arrangements for payment, performance, and the time by which (or
        within which) We undertake to perform the Services;
      </li>
      <li>Our complaints handling policy;</li>
      <li>
        Where applicable, details of after-sales services and commercial
        guarantees;
      </li>
      <li>
        The duration of the Contract, where applicable, or if the Contract is of
        indeterminate duration or is to be extended automatically, the
        conditions for terminating the Contract;
      </li>
      <li>
        Where applicable, the functionality, including appropriate technical
        protection measures of digital content;
      </li>
      <li>
        Where applicable, any relevant compatibility of digital content with
        hardware and software that We are aware of or might reasonably be
        expected to be aware of.
      </li>
    </ul>

    <h2 className="text-2xl font-semibold mt-8 mb-4">Orders</h2>
    <p className="mb-4">
      All Orders for Services made by you will be subject to these Terms and
      Conditions. You may change your Order at any time before We begin
      providing the Services by contacting Us. [Requests to change Orders do not
      need to be made in writing.]
    </p>
    <p className="mb-4">
      If your Order is changed we will inform you of any change to the Price in
      writing. You may cancel your Order within thirty days (30-days) of placing
      it. If you have already made any payments to Us under Clause 5 (including
      but not limited to the Deposit) subject to sub-Clause 5.6 the payment(s)
      will be refunded to you as soon as is reasonably possible and in any event
      within 14 Calendar Days of Our acceptance of your cancellation. If you
      request that your Order be cancelled you must confirm this in writing. If
      you wish to cancel the Services after this time period or once We have
      begun providing the Services please refer to Clause 11.
    </p>
    <p className="mb-4">
      We may cancel your Order at any time before We begin providing the
      Services due to the unavailability of required personnel or materials or
      due to the occurrence of an event outside of Our reasonable control. If
      such cancellation is necessary We will inform you as soon as is reasonably
      possible. If you have made any payments to Us under Clause 5 (including
      but not limited to the Deposit) the payment(s) will be refunded as soon as
      is reasonably possible and in any event within 14 Calendar Days of Us
      informing you of the cancellation. Cancellations will be confirmed in
      writing.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">Price and Payment</h2>
    <p className="mb-4">
      The Price of the Services will be that shown in Our price list in place
      prior to and at the time of your Order. If the Price shown in your Order
      differs from Our current Price We will inform you upon receipt of your
      Order.
    </p>
    <p className="mb-4">
      If We quote a Special Price which is different to the Price shown in Our
      current price list the Special Price will be valid for that period (month,
      quarter, annual contracted) for for a specific service or if the Special
      Price is part of an advertised special offer for the period shown in the
      advertisement. Orders placed during this period will be accepted at the
      Special Price even if We do not accept the Order until after the period
      has expired.
    </p>
    <p className="mb-4">
      Our Prices may change at any time but these changes will not affect Orders
      that We have already accepted. All Prices include VAT. If the rate of VAT
      changes between the date of your Order and the date of your payment We
      will adjust the rate of VAT that you must pay. Changes in VAT will not
      affect any Prices where We have already received payment in full from you.
    </p>
    <p className="mb-4">
      Before We begin providing the Services you will be required to prepay one
      hundred% of the total subscription Price for the Services. The due date
      for payment of your Deposit will be included in the Order Confirmation.
    </p>
    <p className="mb-4">
      In certain circumstances if your Order is cancelled your Deposit will be
      refunded in full or in part. The amount due will be calculated based upon
      the Price for the Services Our price list and the amount of work (if any)
      already undertaken by Us. Please refer to sub-Clauses 4.4 and 4.5 if your
      Order is cancelled before the Services begin or to Clause 11 if the
      Services are cancelled after they have begun.
    </p>
    <p className="mb-4">
      The balance of the Price will be payable once We have provided the
      Services OR as agreed on a month, quarter, or annual basis in advance
      during the provision of the Services.
    </p>
    <p className="mb-4">
      We accept the following methods of payment: major debit card, major credit
      card, special orders, and large contracts will be invoice separately;
      Credit and/or debit cards will be charged once you have entered your card
      and confirmed your order.
    </p>
    <p className="mb-4">
      If you do not make payment to Us by the due date as shown in/on invoice
      and Order Confirmation We may charge you interest on the overdue sum at
      the rate of 4% per annum above the base lending rate of HSBC from time to
      time. Interest will accrue on a daily basis from the due date for payment
      until the actual date of payment of the overdue sum, whether before or
      after judgment. You must pay any interest due when paying an overdue sum.
    </p>
    <p className="mb-4">
      The provisions of sub-Clause 5.10 will not apply if you have promptly
      contacted Us to dispute an invoice in good faith. No interest will accrue
      while such a dispute is ongoing.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">Providing the Services</h2>
    <p className="mb-4">
      As required by law We will provide the Services with reasonable skill and
      care consistent with best practices and standards in the online health and
      wellness sector and in accordance with any information provided by Us
      about the Services and about Us.
    </p>
    <p className="mb-4">
      We will begin providing the Services on the date specified in your Order
      (and confirmed in Our Order Confirmation). We will continue providing the
      Services for the contracted period however these will expire after the
      contractual period has expired.
    </p>
    <p className="mb-4">
      We will make every reasonable effort to complete the Services on time (and
      in accordance with your Order). We cannot however be held responsible for
      any delays if an event outside of Our control occurs. Please see Clause 10
      for events outside of Our control.
    </p>
    <p className="mb-4">
      If We require any information or action from you in order to provide the
      Services We will inform you of this as soon as is reasonably possible.
      Examples of what we may require include: health or medical data to support
      your ai coaches for example your current location in the event self-harm
      or suicide ideation becomes a reality.
    </p>
    <p className="mb-4">
      If the information or action required of you under sub-Clause 6.5 is
      delayed, incomplete, or otherwise incorrect We will not be responsible for
      any delay caused as a result. If additional work is required from Us to
      correct or compensate for a mistake made as a result of incomplete or
      otherwise incorrect information or action on your part We may charge you a
      reasonable additional sum for that work.
    </p>
    <p className="mb-4">
      In certain circumstances for example where there is a delay in you sending
      Us information or taking action required under sub-Clause 6.5 We may
      suspend the Services (and will inform you of that suspension in writing).
    </p>
    <p className="mb-4">
      In certain circumstances for example where We encounter a technical
      problem We may need to suspend the Services in order to resolve the issue.
      Unless the issue is an emergency and requires immediate attention We will
      inform you in advance in writing before suspending the Services.
    </p>
    <p className="mb-4">
      If the Services are suspended under sub-Clauses 6.7 or 6.8 you will not be
      required to pay for them during the period of suspension. You must however
      pay any invoices that you have already received from Us by their due
      date(s).
    </p>
    <p className="mb-4">
      If you do not pay Us for the Services as required by Clause 5 We may
      suspend the Services until you have paid all outstanding sums due. If this
      happens We will inform you in writing. This does not affect Our right to
      charge you interest under sub-Clause 5.10.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">
      Problems with the Services and Your Legal Rights
    </h2>
    <p className="mb-4">
      We always use reasonable efforts to ensure that Our provision of the
      Services is trouble-free. If however there is a problem with the Services
      We request that you inform Us as soon as is reasonably possible (you do
      not need to contact Us in writing) but there is a message centre where you
      can write to admin or IT directly.
    </p>
    <p className="mb-4">
      We will use reasonable efforts to remedy problems with the Services as
      quickly as is reasonably possible and practical. In emergency situations
      such as those where vulnerable people living in your property may be
      affected We will use reasonable efforts to remedy problems within 24
      hours.
    </p>
    <p className="mb-4">
      We will not charge you for remedying problems under this Clause 7 where
      the problems have been caused by Us, any of our agents or employees, or
      sub-contractors, or where nobody is at fault. If We determine that a
      problem has been caused by incorrect or incomplete information or action
      provided or taken by you, sub-Clause 6.6 will apply and We may charge you
      for remedial work.
    </p>
    <p className="mb-4">
      As a consumer you have certain legal rights with respect to the purchase
      of services. For full details of your legal rights and guidance on
      exercising them it is recommended that you contact your local Citizens
      Advice Bureau or Trading Standards Office. If We do not perform the
      Services with reasonable skill and care you have the right to request
      repeat performance or if that is not possible or done within a reasonable
      time without inconvenience to you you have the right to a reduction in
      price. If the Services are not performed in line with information that We
      have provided about them you also have the right to request repeat
      performance or if that is not possible or done within a reasonable time
      without inconvenience to you (or if Our breach concerns information about
      Us that does not relate to the performance of the Services) you have the
      right to a reduction in price. If for any reason We are required to repeat
      the Services in accordance with your legal rights We will not charge you
      for the same and We will bear any and all costs of such repeat
      performance. In cases where a price reduction applies this may be any sum
      up to the full Price and where you have already made payment(s) to Us may
      result in a full or partial refund. Any such refunds will be issued
      without undue delay (and in any event within 14 calendar days starting on
      the date on which We agree that you are entitled to the refund) and made
      via the same payment method originally used by you unless you request an
      alternative method. In addition to your legal rights relating directly to
      the Services You also have remedies if We use materials that are faulty or
      incorrectly described.
    </p>
    <p className="mb-4">
      As a consumer you have certain legal rights with respect to digital
      content. If any work produced for you under these Terms and Conditions is
      supplied as digital content these rights may apply to you. For full
      details of your legal rights and guidance on exercising them it is
      recommended that you contact your local Citizens Advice Bureau or Trading
      Standards Office. Any digital content that We supply to you must be of
      satisfactory quality it must be fit for purpose (where any such purpose
      has been made known to Us whether expressly or by implication) and it must
      match any description given by Us. Due to the nature of digital content
      you are unable to reject digital content which does not comply with the
      above; however you may have the right to a repair or replacement or if
      these are not possible to a price reduction up to the full Price. In cases
      where a price reduction applies and you have already made payment(s) to Us
      you may be due a full or partial refund. Any such refunds will be issued
      without undue delay (and in any event within 14 Calendar Days starting on
      the date on which We agree that you are entitled to the refund) and made
      via the same payment method originally used by you unless you request an
      alternative method. If digital content provided by Us under these Terms
      and Conditions damages your device(s) as a result of Our failure to
      exercise reasonable skill and care you also have the legal right to
      compensation for such damage which may take the form of a repair or
      replacement or financial compensation.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">
      Intellectual Property Rights
    </h2>
    <p className="mb-4">
      During the course of providing the Services to you We may create or
      produce risk analyses, suggestions, coping skills, mitigating strategies
      to address identified challenges, AI support, Garmin smart watch
      summaries, and other such data for you which embody/embodies certain
      Intellectual Property Rights owned by us (such as copyright or patents).
    </p>
    <p className="mb-4">
      We will retain ownership of any and all Intellectual Property Rights that
      may exist in EFP assessments, Growth Finder Assessment, Holistic
      Bereavement Assessment, and others within our platform. We will grant to
      you a royalty-free non-exclusive licence to use your reports and your
      analysis so that you can provide such to your healthcare providers or
      facility however these cannot be published openly online or distributed to
      others without written permission. The licence granted under this
      sub-Clause 8.2 will continue indefinitely as a perpetual licence. The
      duration of the licence will not necessarily be related to the duration of
      the Services themselves and may continue beyond the duration of the
      Services.
    </p>
    <p className="mb-4">
      If the Contract is cancelled under Clause 11 the licence granted under
      sub-Clause 8.2 may also be cancelled in full or in part and you will no
      longer be permitted to use all or part of My-E-Health ecosystem
      assessments or analyses. Upon cancellation We will explain to you exactly
      which part(s) of analyses reports (if any) that you will remain entitled
      to use.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">Our Liability</h2>
    <p className="mb-4">
      We will be responsible for any foreseeable loss or damage that you may
      suffer as a result of Our breach of these Terms and Conditions or as a
      result of Our negligence (including that of Our employees, agents, or
      sub-contractors). Loss or damage is foreseeable if it is an obvious
      consequence of the breach or negligence or if it is contemplated by you
      and Us when the Contract is created. We will not be responsible for any
      loss or damage that is not foreseeable.
    </p>
    <p className="mb-4">
      We provide Services for domestic and private use (or purposes). By making
      your Order you agree that you will not use the Services for such purposes.
      We will not be liable to you for any loss of profit, loss of business,
      interruption to business, or for any loss of business opportunity.
    </p>
    <p className="mb-4">
      If We are providing Services that cause any damage to you or your computer
      We will make good that damage at no additional cost to you. However, if
      our AI coaches recommend a coping skill or mitigating strategy that you do
      not agree with or it is inconsistent with your core values and dignity you
      are required to report this to Admin or your care team for immediate
      resolution. If you do not report this We are not responsible for any
      pre-existing faults or damage to you or your property that We may discover
      while providing the Services.
    </p>
    <p className="mb-4">
      Nothing in these Terms and Conditions seeks to exclude or limit Our
      liability for death or personal injury caused by Our negligence (including
      that of Our employees, agents, or sub-contractors); or for fraud or
      fraudulent misrepresentation. Nonetheless, it is your responsibility to
      report anything unusual or harmful to your Administration contact within
      your message centre at your earliest convenience.
    </p>
    <p className="mb-4">
      Nothing in these Terms and Conditions seeks to exclude or limit Our
      liability for failing to perform the Services with reasonable care and
      skill or in accordance with information provided by Us about the Services
      or about Us nor for Our failure to supply digital content that is of
      satisfactory quality, fit for purpose, and as described.
    </p>
    <p className="mb-4">
      Nothing in these Terms and Conditions seeks to exclude or limit Your legal
      rights as a consumer. For more details of Your legal rights please refer
      to Your local Citizens Advice Bureau or Trading Standards Office.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">
      Events Outside of Our Control (Force Majeure)
    </h2>
    <p className="mb-4">
      We will not be liable for any failure or delay in performing Our
      obligations where that failure or delay results from any cause that is
      beyond Our reasonable control. Such causes include but are not limited to:
      power failure, internet service provider failure, strikes, lock-outs, or
      other industrial action by third parties, riots and other civil unrest,
      fire, explosion, flood, storms, earthquakes, subsidence, acts of terrorism
      (threatened or actual), acts of war (declared, undeclared, threatened,
      actual, or preparations for war), epidemic or other natural disaster, or
      any other event that is beyond Our reasonable control.
    </p>
    <p className="mb-4">
      If any event described under this Clause 10 occurs that is likely to
      adversely affect Our performance of any of Our obligations under these
      Terms and Conditions:
    </p>
    <ul className="list-disc pl-8 mb-4 space-y-2">
      <li>We will inform you as soon as is reasonably possible;</li>
      <li>
        Our obligations under these Terms and Conditions will be suspended and
        any time limits that We are bound by will be extended accordingly;
      </li>
      <li>
        We will inform you when the event outside of Our control is over and
        provide details of any new dates, times, or availability of Services as
        necessary;
      </li>
      <li>
        If an event outside of Our control occurs and you wish to cancel the
        Contract you may do so in accordance with your right to Cancel under
        sub-Clause 11.3.3;
      </li>
      <li>
        If the event outside of Our control continues for more than two weeks We
        will cancel the Contract in accordance with Our right to cancel under
        sub-Clause 11.6.3 and inform you of the cancellation. Any refunds due to
        you as a result of that cancellation will be paid to you as soon as is
        reasonably possible and in any event within 14 Calendar Days of Our
        cancellation notice.
      </li>
    </ul>

    <h2 className="text-2xl font-semibold mt-8 mb-4">Cancellation</h2>
    <p className="mb-4">
      If you wish to cancel your Order for the Services before the Services
      begin you may do so under sub-Clause 4.4. Once We have begun providing the
      Services you are free to cancel the Services and the Contract at any time
      by giving Us a one-month written notice. For annual subscriptions, the
      term is fixed however if you wish to cancel you may transfer the remainder
      of your time in your account to another person as you desire. The contract
      will not be renewed and will then expire as per the agreed subscription
      period. Since all annual subscriptions are heavily discounted and prepaid
      to Us these remaining Services are transferrable if you so desire. You
      will have to contact Administration with these details. If We have
      provided Services that you have not yet paid for the sums due will be
      deducted from any refund due to you or if no refund is due We will invoice
      you for those sums and you will be required to make payment in accordance
      with Clause 5.
    </p>
    <p className="mb-4">
      If any of the following occur you may cancel the Services and the Contract
      immediately by giving Us written notice. If you have made any payment to
      Us for any Services We have not yet provided these sums will be refunded
      to you as soon as is reasonably possible and in any event within 14
      Calendar Days of Our acceptance of your cancellation. If We have provided
      Services that you have not yet paid for the sums due will be deducted from
      any refund due to you or if no refund is due We will invoice you for those
      sums and you will be required to make payment in accordance with Clause 5.
      If you cancel because of Our breach under sub-Clause 11.3.1 you will not
      be required to make any payments to Us. You will not be required to give a
      one-month notice in these circumstances:
    </p>
    <ul className="list-disc pl-8 mb-4 space-y-2">
      <li>
        We have breached the Contract in any material way and have failed to
        remedy that breach within two weeks of you asking Us to do so in
        writing; or
      </li>
      <li>
        We enter into liquidation or have an administrator or receiver appointed
        over Our assets; or
      </li>
      <li>
        We are unable to provide the Services due to an event outside of Our
        control (as under sub-Clause 10.2.4); or
      </li>
      <li>
        We change these Terms and Conditions to your material disadvantage.
      </li>
    </ul>

    <p className="mb-4">
      We may cancel your Order for the Services before the Services begin under
      sub-Clause 4.5. Once We have begun providing the Services We may cancel
      the Services and the Contract at any time by giving you a one-month
      written notice. If you have made any payment to Us for any Services We
      have not yet provided these sums will be refunded to you as soon as is
      reasonably possible and in any event within 14 Calendar Days of Our
      cancellation notice. If We have provided Services that you have not yet
      paid for the sums due will be deducted from any refund due to you or if no
      refund is due We will invoice you for those sums and you will be required
      to make payment in accordance with Clause 5.
    </p>
    <p className="mb-4">
      If any of the following occur We may cancel the Services and the Contract
      immediately by giving you written notice. If you have made any payment to
      Us for any Services We have not yet provided these sums will be refunded
      to you as soon as is reasonably possible and in any event within 14
      Calendar Days of Our cancellation notice. If We have provided Services
      that you have not yet paid for the sums due will be deducted from any
      refund due to you or if no refund is due We will invoice you for those
      sums and you will be required to make payment in accordance with Clause 5.
      We will not be required to give [insert period] notice in these
      circumstances:
    </p>

    <ul className="list-disc pl-8 mb-4 space-y-2">
      <li>
        You fail to make a payment on time as required under Clause 5 (this does
        not affect our right to charge interest on overdue sums under sub-Clause
        5.10); or
      </li>
      <li>
        You have breached the Contract in any material way and have failed to
        remedy that breach within two-weeks of Us asking you to do so in
        writing; or
      </li>
      <li>
        We are unable to provide the Services due to an event outside of Our
        control (for a period longer than that in sub-Clause 10.2.5).
      </li>
    </ul>

    <p className="mb-4">
      For the purposes of this Clause 11 (and in particular sub-Clauses 11.3.1
      and 11.6.2) a breach of the Contract will be considered ‘material’ if it
      is not minimal or trivial in its consequences to the terminating party
      (i.e. you under sub-Clause 11.3.1 and Us under sub-Clause 11.6.2). In
      deciding whether or not a breach is material no regard will be had to
      whether it was caused by any accident, mishap, mistake, or
      misunderstanding.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">
      Communication and Contact Details
    </h2>
    <p className="mb-4">
      If you wish to contact Us you may do so by telephone at +46704065696 or by
      email at info@my-e-health.com.
    </p>
    <p className="mb-4">
      In certain circumstances you must contact Us in writing (when cancelling
      an Order for example or exercising your right to cancel the Services).
      When contacting Us in writing you may use the following methods:
    </p>
    <ul className="list-disc pl-8 mb-4 space-y-2">
      <li>Contact Us by email at info@my-e-health.com; or</li>
      <li>
        Contact Us by pre-paid post at My-E-Health UK Ltd, Lowin House, Tregolls
        Road, Truro, Cornwall TR1 2NA.
      </li>
    </ul>

    <h2 className="text-2xl font-semibold mt-8 mb-4">
      Complaints and Feedback
    </h2>
    <p className="mb-4">
      We always welcome feedback from Our customers and whilst We always use all
      reasonable endeavours to ensure that your experience as a customer of Ours
      is a positive one We nevertheless want to hear from you if you have any
      cause for complaint.
    </p>
    <p className="mb-4">
      All complaints are handled in accordance with Our complaints handling
      policy and procedure available from Administration.
    </p>
    <p className="mb-4">
      If you wish to complain about any aspect of your dealings with Us please
      contact Us in one of the following ways:
    </p>
    <ul className="list-disc pl-8 mb-4 space-y-2">
      <li>In writing via your Message Centre addressed to Admin</li>
      <li>By email addressed to info@my-e-health.com;</li>
      <li>By contacting Us by telephone on +46704065696.</li>
    </ul>

    <h2 className="text-2xl font-semibold mt-8 mb-4">
      How We Use Your Personal Information (Data Protection)
    </h2>
    <p className="mb-4">
      We will only use your personal information as set out in Our Privacy
      Notice available from the main webpage and also found within your personal
      account setting under legal documents.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">Other Important Terms</h2>
    <p className="mb-4">
      We may transfer (assign) Our obligations and rights under these Terms and
      Conditions (and under the Contract as applicable) to a third party (this
      may happen for example if We sell Our business). If this occurs you will
      be informed by Us in writing. Your rights under these Terms and Conditions
      will not be affected and Our obligations under these Terms and Conditions
      will be transferred to the third party who will remain bound by them.
    </p>
    <p className="mb-4">
      You may not transfer (assign) your obligations and rights under these
      Terms and Conditions (and under the Contract as applicable) without Our
      express written permission.
    </p>
    <p className="mb-4">
      The Contract is between you and Us. It is not intended to benefit any
      other person or third party in any way and no such person or party will be
      entitled to enforce any provision of these Terms and Conditions.
    </p>
    <p className="mb-4">
      If any of the provisions of these Terms and Conditions are found to be
      unlawful, invalid, or otherwise unenforceable by any court or other
      authority that / those provision(s) shall be deemed severed from the
      remainder of these Terms and Conditions. The remainder of these Terms and
      Conditions shall be valid and enforceable.
    </p>
    <p className="mb-4">
      No failure or delay by Us in exercising any of Our rights under these
      Terms and Conditions means that We have waived that right and no waiver by
      Us of a breach of any provision of these Terms and Conditions means that
      We will waive any subsequent breach of the same or any other provision.
    </p>

    <h2 className="text-2xl font-semibold mt-8 mb-4">
      Governing Law and Jurisdiction
    </h2>
    <p className="mb-4">
      These Terms and Conditions, the Contract, and the relationship between you
      and Us (whether contractual or otherwise) shall be governed by and
      construed in accordance with the law of England & Wales, Northern Ireland,
      Scotland, and the Kingdom of Sweden.
    </p>
    <p className="mb-4">
      As a consumer you will benefit from any mandatory provisions of the law in
      your country of residence. Nothing in Sub-Clause 16.1 above takes away or
      reduces your rights as a consumer to rely on those provisions.
    </p>
    <p className="mb-4">
      Any dispute, controversy, proceedings, or claim between you and Us
      relating to these Terms and Conditions, the Contract, or the relationship
      between you and Us (whether contractual or otherwise) shall be subject to
      the jurisdiction of the courts of England, Wales, Scotland, or Northern
      Ireland as determined by your residency.
    </p>

    <footer className="mt-8 text-sm text-gray-600">
      <p>© My-E-Health Standard Service Terms: SST001</p>
    </footer>
  </div>
);

export default TermsAndConditions;
