import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./index.css";
import { Navbar } from "./navbar";
import Hero from "./hero";
import { Services } from "./services";
import { About } from "./about";
import { Pricing } from "./pricing";
import { Partners } from "./partners";
import Component from "./component";
import { Selfcare } from "./selfcare";
import { Contact24 } from "./contact";
import { Footer } from "./footer";
import { Testimonals } from "./testimonals";
import AboutUs from "./aboutUs";
import Privacy from "./privacy";
import { Business } from "./business";
import { CareGiver } from "./caregiver";
import { Individual } from "./individual";
import { Sport } from "./sport";
import Research from "./research";
import { Garmin } from "./garmin";
import Cookie from "./cookies";
import TermsAndConditions from "./terms";

const MainContent = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const section = document.getElementById(location.state.scrollTo);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.state]);

  return (
    <div>
      <div className="relative hero-section" id="hero">
        <Hero />
      </div>
      <div id="partners">
        <Partners />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="component">
        <Component />
      </div>
      <div id="services">
        <Services />
      </div>
     <div id="garmin">
        <Garmin />
      </div>
      <div id="selfcare" className="py-12 md:py-16 lg:py-20">
        <Selfcare />
      </div>
      <div id="testimonals">
        <Testimonals />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div
        className="bg-[url(../assets/images/53e8cf6e-da8a-4a5f-80e8-7860917cc506.png)] bg-cover bg-no-repeat w-full"
        id="contact"
      >
        <Contact24 />
      </div>
      <Footer />
    </div>
  );
};

export default function Main() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [isHeroSection, setIsHeroSection] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const heroSection = document.getElementById("hero");
    if (heroSection) {
      const heroRect = heroSection.getBoundingClientRect();
      const isHero =
        heroRect.top >= -90 && heroRect.bottom <= window.innerHeight + 90;
      setIsHeroSection(isHero);
    }

    const currentScrollY = window.scrollY;

    if (currentScrollY < lastScrollY || currentScrollY < 100) {
      setIsNavbarVisible(true);
    } else if (currentScrollY > lastScrollY) {
      setIsNavbarVisible(false);
    }

    setLastScrollY(currentScrollY);
  };

  const handleMouseMove = (event) => {
    if (event.clientY <= 100) {
      setIsNavbarVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [lastScrollY]);

  const navbarClass = `navbar-fixed ${
    isHeroSection ? "" : isNavbarVisible ? "navbar-visible" : "navbar-hidden"
  }`;

  return (
    <Router>
      <div className="main-container mx-auto my-0">
        <div className={navbarClass}>
          <Navbar />
        </div>
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/business" element={<Business />} />
          <Route path="/caregiver" element={<CareGiver />} />
          <Route path="/individual" element={<Individual />} />
          <Route path="/sport" element={<Sport />} />
          <Route path="/research" element={<Research />} />
          <Route path="/cookie-policy" element={<Cookie />} />
          <Route path="/t&c" element={<TermsAndConditions />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </div>
    </Router>
  );
}
